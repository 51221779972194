import React from 'react'

const TaptonGreen: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_9552_33809)">
        <g clipPath="url(#clip1_9552_33809)">
          <path
            d="M11.1657 39.8975H27.7154C29.8655 39.8975 31.6391 38.2356 31.7447 36.1193L33.3369 4.31923C33.4505 2.04951 31.6132 0.148438 29.3076 0.148438H9.54962C7.24203 0.148438 5.40473 2.0515 5.52031 4.32322L7.13442 36.1233C7.24203 38.2396 9.01357 39.8995 11.1637 39.8995L11.1657 39.8975Z"
            fill="#00E100"
          />
          <path
            d="M13.1472 35.829H25.7134C26.7556 35.829 27.6304 34.9024 27.734 33.6868L29.3581 6.73312C29.4776 5.33223 28.5391 4.11865 27.3374 4.11865H11.5231C10.3215 4.11865 9.3829 5.33422 9.50247 6.73312L11.1265 33.6868C11.2302 34.9024 12.103 35.829 13.1472 35.829Z"
            fill="white"
          />
          <path
            d="M19.6539 32.3273C20.7544 32.3273 21.6466 31.4351 21.6466 30.3345C21.6466 29.234 20.7544 28.3418 19.6539 28.3418C18.5533 28.3418 17.6611 29.234 17.6611 30.3345C17.6611 31.4351 18.5533 32.3273 19.6539 32.3273Z"
            fill="#00E100"
          />
          <path
            d="M17.3936 1.13709C16.2931 1.13709 15.4009 2.02927 15.4009 3.12982C15.4009 4.23038 16.2931 5.12256 17.3936 5.12256H21.4767C22.5773 5.12256 23.4695 4.23038 23.4695 3.12982C23.4695 2.02927 22.5773 1.13709 21.4767 1.13709L17.3936 1.13709Z"
            fill="#00E100"
          />
          <path
            d="M21.6575 23.7543C20.8584 23.4738 20.1819 22.8561 19.7969 22.0578L14.299 10.611C13.8854 9.74985 13.8492 8.77619 14.1984 7.93785C14.5083 7.19342 15.0862 6.64232 15.8238 6.38808L30.4302 1.311C31.0271 1.10873 31.6604 1.11563 32.2715 1.33017C33.0706 1.61072 33.7521 2.23224 34.1376 3.03499L38.1115 11.3089C38.8131 12.7695 38.3978 14.4759 37.1454 15.276L24.0468 23.5118C23.3427 23.9531 22.471 24.042 21.6568 23.7562L21.6575 23.7543Z"
            fill="#00E100"
          />
          <path
            d="M31.948 2.25398C32.4857 2.44277 32.9706 2.86643 33.2549 3.45832L37.2288 11.7322C37.7181 12.7509 37.4486 13.9213 36.619 14.4495L23.5255 22.6829C23.0538 22.9798 22.4936 23.0113 21.9822 22.8317C21.4708 22.6522 20.9652 22.2276 20.6809 21.6357L15.183 10.1889C14.6254 9.02799 15.0709 7.68699 16.145 7.31646L30.7539 2.23816C31.1449 2.10509 31.56 2.11987 31.9492 2.25652M32.5962 0.407595C31.7783 0.120448 30.922 0.111255 30.1236 0.382179L30.1166 0.383959L30.1096 0.385739L15.5007 5.46404C14.5007 5.80991 13.7156 6.55435 13.295 7.55983C12.8383 8.65612 12.8819 9.9217 13.4163 11.0344L18.9141 22.4811C19.4095 23.5125 20.2905 24.3118 21.3321 24.6775C22.4302 25.063 23.6075 24.9399 24.5637 24.3399L37.6597 16.1053L37.6655 16.101L37.6712 16.0967C39.34 15.031 39.9075 12.7888 38.9912 10.881L35.0173 2.60714C34.5207 1.57323 33.6373 0.768873 32.5938 0.402513L32.5962 0.407595Z"
            fill="white"
          />
          <path
            d="M24.1269 16.4817L22.2012 17.4066C21.6705 17.6615 21.4469 18.2984 21.7018 18.8292L21.7027 18.831C21.9576 19.3617 22.5945 19.5853 23.1253 19.3304L25.0509 18.4055C25.5816 18.1506 25.8052 17.5137 25.5503 16.983L25.5495 16.9812C25.2945 16.4504 24.6576 16.2268 24.1269 16.4817Z"
            fill="white"
          />
          <path
            d="M33.8956 4.794L35.2157 7.54264L17.6813 15.3954L15.7139 11.296L33.8956 4.794Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9552_33809">
          <rect width="40" height="40" fill="white" />
        </clipPath>
        <clipPath id="clip1_9552_33809">
          <rect
            width="33.6"
            height="42.1"
            fill="white"
            transform="translate(5.2002 -2.1001)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TaptonGreen
